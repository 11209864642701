<template>
  <div v-if="mobileWidth">
    <mobile />
  </div>
  <div class="container" v-else>
    <div :class="['box', boxHeight]">
      <div class="box-title">Register</div>
      <div class="step">
        <Steps :current="step">
          <Step title="Personal Details"></Step>
          <Step title="Company Info"></Step>
          <Step title="Product Category"></Step>
        </Steps>
      </div>
      <div class="border"></div>

      <div v-show="step === 0">
        <div class="form-box">
          <div class="title">
            Let's start with personal details
          </div>

          <div class="content">
            <Card>
              <div class="form">
                <Form
                  ref="form1"
                  :model="form1"
                  :rules="rules1"
                  class="register-form"
                  :label-width="210"
                >
                  <Row :gutter="20">
                    <i-col span="12">
                      <FormItem
                        prop="first_name"
                        :rules="required"
                        label="First Name"
                      >
                        <Input v-model="form1.first_name" />
                      </FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem
                        prop="last_name"
                        :rules="required"
                        label="Last Name"
                      >
                        <Input v-model="form1.last_name"
                      /></FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem
                        prop="phone"
                        :rules="required"
                        label="Phone Number"
                      >
                        <Input v-model="form1.phone" />
                      </FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem prop="email" label="Email">
                        <Input v-model="form1.email"
                      /></FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem prop="password" label="Password">
                        <Input type="password" v-model="form1.password" />
                      </FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem prop="re_password" label="Re-enter Password">
                        <Input type="password" v-model="form1.re_password"
                      /></FormItem>
                    </i-col>
                  </Row>
                </Form>
              </div>
            </Card>
          </div>
          <div class="btns">
            <!--            <p class="tipEng">Please fill form in English.</p>-->
            <Button
              :loading="form1ValidateLoading"
              type="primary"
              @click="form1Validate"
              >Continue</Button
            >
          </div>
        </div>
      </div>

      <div v-show="step === 1">
        <Modal
          v-model="modal"
          title="Change Avatar"
          footer-hide
          :mask-closable="false"
        >
          <Alert show-icon>
            We recommend that you upload a photo with a minimum dimension of 400
            x 400.
          </Alert>

          <Cropper
            :stencil-props="{
              aspectRatio: 1
            }"
            classname="cropper"
            :src="image"
            ref="cropper"
          />

          <Button class="mr-20" @click="$refs.file.click()">
            <input
              v-show="false"
              type="file"
              ref="file"
              @change="uploadImage($event)"
              accept="image/*"
            />
            Choose image
          </Button>

          <Button
            :loading="uplodLoading"
            :disabled="!image"
            @click="crop"
            type="primary"
          >
            Upload
          </Button>
        </Modal>

        <div class="form-box">
          <div class="title">
            You're almost done... Tell us about your company
          </div>

          <div class="content">
            <Card>
              <div class="form">
                <Form
                  ref="form2"
                  :model="form2"
                  class="register-form"
                  :label-width="210"
                >
                  <FormItem
                    prop="company_name"
                    :rules="required"
                    label="Name of Company"
                  >
                    <Input v-model="form2.company_name" />
                  </FormItem>

                  <Row :gutter="20">
                    <i-col span="12">
                      <FormItem
                        prop="established_year"
                        :rules="required"
                        label="Established Year"
                      >
                        <InputNumber
                          :style="{ width: '100%' }"
                          v-model="form2.established_year"
                        />
                      </FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem
                        prop="company_tax_id"
                        :rules="required"
                        label="Tax Id"
                      >
                        <Input v-model="form2.company_tax_id"
                      /></FormItem>
                    </i-col>
                  </Row>

                  <FormItem
                    prop="company_description"
                    :rules="required"
                    label="Company Description"
                  >
                    <Input
                      type="textarea"
                      v-model="form2.company_description"
                    />
                  </FormItem>

                  <FormItem
                    prop="business_type"
                    :rules="required"
                    label="What Type of Business?"
                  >
                    <Select v-model="form2.business_type">
                      <Option
                        v-for="item in business_type"
                        :key="item"
                        :value="item"
                        >{{ item }}</Option
                      >
                    </Select>
                    <Input
                      placeholder="Others"
                      v-if="form2.business_type === 'Others'"
                      v-model="other_business_type"
                    />
                  </FormItem>

                  <FormItem
                    prop="company_annual_revenue"
                    label="What is Your Company's Annual Revenue (in Dollars)?"
                  >
                    <Select v-model="form2.company_annual_revenue">
                      <Option
                        v-for="item in company_annual_revenue"
                        :key="item"
                        :value="item"
                        >{{ item }}</Option
                      >
                    </Select>
                  </FormItem>

                  <FormItem
                    prop="company_size"
                    label="What is The Size of Your Company?"
                  >
                    <Select v-model="form2.company_size">
                      <Option
                        v-for="item in company_size"
                        :key="item"
                        :value="item"
                        >{{ item }}</Option
                      >
                    </Select>
                  </FormItem>

                  <FormItem
                    prop="job_title"
                    :rules="required"
                    label=" What is Your Job Title?"
                  >
                    <Select v-model="form2.job_title">
                      <Option
                        v-for="item in job_title"
                        :key="item"
                        :value="item"
                        >{{ item }}</Option
                      >
                    </Select>
                    <Input
                      placeholder="Other"
                      v-if="form2.job_title === 'Other'"
                      v-model="other_job_title"
                    />
                  </FormItem>

                  <FormItem
                    prop="company_city_address"
                    :rules="required"
                    label="Company City Address"
                  >
                    <Input v-model="form2.company_city_address" />
                  </FormItem>

                  <Row :gutter="20">
                    <i-col span="12">
                      <FormItem
                        prop="company_street_address"
                        :rules="required"
                        label="Street Address"
                      >
                        <Input v-model="form2.company_street_address" />
                      </FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem
                        prop="company_state_address"
                        :rules="required"
                        label="State"
                      >
                        <Input v-model="form2.company_state_address"
                      /></FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem
                        prop="company_zip_code"
                        :rules="required"
                        label="Zip or Postal Code"
                      >
                        <Input v-model="form2.company_zip_code" />
                      </FormItem>
                    </i-col>

                    <i-col span="12">
                      <FormItem
                        label="Country"
                        :rules="required"
                        prop="company_country_address"
                      >
                        <Select
                          filterable
                          v-model="form2.company_country_address"
                        >
                          <Option
                            v-for="item in countryList"
                            :value="item.id"
                            :key="item.id"
                            >{{ item.name }}</Option
                          >
                        </Select>
                      </FormItem>
                    </i-col>
                  </Row>

                  <FormItem label="Website">
                    <Input v-model="form2.website" />
                  </FormItem>

                  <FormItem
                    prop="company_avatar"
                    :rules="required"
                    label="Company Avatar"
                  >
                    <div
                      v-if="!form2.company_avatar"
                      class="upload"
                      @click="modal = true"
                    >
                      <div class="upload-inner">
                        <Icon
                          class="icon"
                          type="ios-cloud-upload"
                          size="52"
                        ></Icon>
                        <p>Click here to upload avatar</p>
                      </div>
                      <Alert
                        show-icon
                        style="position: relative; top: 30px;left: 10px;height: 45px;"
                      >
                        We recommend that you upload a photo with a minimum
                        dimension of 400 x 400.
                      </Alert>
                    </div>

                    <div class="upload" v-else>
                      <Alert type="success">File upload successful</Alert>
                      <div
                        style="display:inline-block;width:50px;height:50px;border-radius: 50%;border:1px solid #f2f2f2;margin-right: 20px;vertical-align: middle;"
                      >
                        <img
                          alt=""
                          :src="form2.company_avatar"
                          style="width:100%;border-radius: 50%;"
                        />
                      </div>
                      <Button @click="form2.company_avatar = undefined"
                        >Choose Again</Button
                      >
                    </div>
                  </FormItem>

                  <FormItem prop="accept_policy" :rules="required">
                    <CheckboxGroup v-model="form2.accept_policy">
                      <Checkbox label="1"><span /></Checkbox>
                      <span
                        >I have read and acknowledged the
                        <router-link
                          target="_blank"
                          class="link"
                          to="/user-action/privacy"
                          >privacy policy</router-link
                        >
                        and accept the
                        <router-link
                          target="_blank"
                          class="link"
                          to="/user-action/terms"
                          >terms and conditions</router-link
                        >
                      </span>
                    </CheckboxGroup>
                  </FormItem>
                </Form>
              </div>
            </Card>
          </div>

          <div class="btns">
            <!--            <p class="tipEng">Please fill form in English.</p>-->

            <Button class="back" @click="back">Back</Button>

            <Button :loading="loading" type="primary" @click="finishRegister"
              >Finish Registration</Button
            >
          </div>
        </div>
      </div>
      <div v-show="step === 2">
        <div class="form-box">
          <div class="top-title">
            Never miss a business opportunity again. Receive notifications for
            inquiries, new products and more.
          </div>
          <div class="title product-title" style="margin-bottom: 0;">
            Industries you might be interested in
          </div>

          <div class="content product-content">
            <div
              :class="['item', { active: industryActive === item }]"
              @click="industryActive = item"
              v-for="(item, index) in company_focus_industry"
              :key="index"
            >
              <div class="pic">
                <img
                  src=""
                  v-if="industryActive === item"
                />
                <img
                  src=""
                  v-else
                />
              </div>
              <div class="word">{{ item }}</div>
            </div>
          </div>
          <div class="title product-title" style="margin-bottom: 10px;">
            Your company's focus area
          </div>
          <div class="content product-content">
            <div
              :class="['item', { active: areaActive === item }]"
              @click="areaActive = item"
              v-for="(item, index) in company_focus_area"
              :key="index"
            >
              <div class="pic">
                <img
                  src=""
                  v-if="areaActive === item"
                />
                <img
                  src=""
                  v-else
                />
              </div>
              <div class="word">{{ item }}</div>
            </div>
          </div>
          <div class="btns">
            <Button @click="skip" class="back">Skip</Button>

            <Button :loading="loading" type="primary" @click="finishAll"
              >Finish</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
const {
  _register,
  _emailState,
  _registerInfo,
  _country,
  _updateIndustry
} = api;
import { trim } from "lodash";
import S3 from "aws-s3";
import { Cropper } from "vue-advanced-cropper";
import { dataURLtoBlob, blobToFile } from "@/utils/helper";
import mobile from "./register-exhibitor-mobile";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  components: {
    Cropper,
    mobile
  },
  computed: {
    ...mapState("user", ["timezone"])
  },
  created() {
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
    _registerInfo()
      .then(response => {
        const {
          data: {
            business_type,
            company_annual_revenue,
            // company_attend_trade_shows,
            company_focus_area,
            company_focus_industry,
            company_size,
            // interested_in_exhibiting,
            job_title
          }
        } = response;

        this.business_type = business_type;
        this.company_annual_revenue = company_annual_revenue;
        // this.company_attend_trade_shows = company_attend_trade_shows;
        this.company_focus_area = company_focus_area;
        this.company_focus_industry = company_focus_industry;
        this.company_size = company_size;
        // this.interested_in_exhibiting = interested_in_exhibiting;
        this.job_title = job_title;
      })
      .catch(({ message }) => {
        this.$Message.error(message);
      });
  },

  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      image: null,
      modal: false,

      other_business_type: "",
      business_type: [], // Others
      company_annual_revenue: [],
      company_attend_trade_shows: ["input a trade show"],
      company_focus_area: [],
      company_focus_industry: [], // Others
      company_size: [],
      // interested_in_exhibiting: [],
      job_title: [],
      countryList: [],

      loading: false,
      form1ValidateLoading: false,

      file: null,
      uplodLoading: false,

      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      step: 0,
      form1: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        password: null,
        re_password: null
      },
      rules1: {
        email: [
          {
            required: true,
            message: "Please fill"
          },
          { type: "email", message: "Incorrect email format" }
        ],
        password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          }
        ],
        re_password: [
          {
            required: true,
            message: "Please fill"
          },
          {
            min: 6,
            message: "No less than 6 characters"
          },
          {
            message: "The confirmation password is not identical",
            validator: (rule, value) => {
              return value === this.form1.password;
            }
          }
        ]
      },

      form2: {
        company_name: null,
        established_year: null,
        company_tax_id: null,
        company_description: null,
        business_type: null,
        company_street_address: null,
        company_state_address: null,
        company_zip_code: null,
        company_country_address: null,
        company_city_address: null,
        accept_policy: [],
        company_avatar: null
      },
      boxHeight: "box-550",
      industryActive: "",
      areaActive: "",
      tempToken: ""
    };
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      const base64Data = canvas.toDataURL();
      const blob = dataURLtoBlob(base64Data);
      const file = blobToFile(blob);

      this.uplodLoading = true;
      uploader
        .uploadFile(file)
        .then(data => {
          this.modal = false;
          this.form2.company_avatar = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
        });
    },

    uploadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    back() {
      this.step--;
      this.boxHeight = "box-550";
      if (2 === this.step) {
        this.boxHeight = "box-1580";
      }
      if (1 === this.step) {
        this.boxHeight = "box-step-2";
      }
    },

    next() {
      this.step++;
      if (2 === this.step) {
        this.boxHeight = "box-1580";
      }
      if (1 === this.step) {
        this.boxHeight = "box-step-2";
      }
    },

    removeFile(tag) {
      this.file = null;
      if (tag == "img") this.form2.company_avatar = "";
      else this.form3.proof = "";
    },

    // handleUploadImg(e) {
    //   const file = e.target.files[0];
    //   if (file) {
    //     if (file.size > 2 * 1024 * 1024) {
    //       this.$Message.error("File size cannot exceed 2MB");
    //     } else {
    //       this.file = file;
    //       this.uploadImg();
    //     }
    //   }
    // },

    // uploadImg() {
    //   this.uplodLoading = true;
    //   uploader
    //     .uploadFile(this.file)
    //     .then(data => {
    //       console.log(data);
    //       this.form2.company_avatar = data.location;
    //     })
    //     .catch(() => {
    //       this.$Message.error("upload error");
    //     })
    //     .finally(() => {
    //       this.uplodLoading = false;
    //     });
    // },

    form1Validate() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          this.form1ValidateLoading = true;
          _emailState({
            email: this.form1.email
          })
            .then(() => {
              this.boxHeight = "box-1480";
              this.next();
            })
            .catch(response => {
              if (response.code === -2) {
                this.$Notice.warning({
                  title: "The email is not activated",
                  duration: 0,
                  render: h => {
                    return h("span", [
                      "Please ",
                      h(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: `/user-action/register-success?email=${encodeURIComponent(
                              this.form1.email
                            )}`
                          }
                        },
                        "active"
                      ),
                      " email"
                    ]);
                  }
                });
              } else {
                this.$Message.error(response.message);
              }
            })
            .finally(() => {
              this.form1ValidateLoading = false;
            });
        }
      });
    },
    skip() {
      this.$router.push(
        `/user-action/register-success?email=${encodeURIComponent(
          this.form1.email
        )}`
      );
    },
    finishAll() {
      if ("" === this.areaActive && "" === this.industryActive) {
        this.$Message.error("Please select an area or industry");
        return;
      }
      _updateIndustry({
        company_focus_area: this.areaActive,
        company_focus_industry: this.industryActive,
        token: this.tempToken
      })
        .then()
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
      this.$router.push(
        `/user-action/register-success?email=${encodeURIComponent(
          this.form1.email
        )}`
      );
    },
    finishRegister() {
      this.$refs.form2.validate(valid => {
        if (valid) {
          const form1 = JSON.parse(JSON.stringify(this.form1));
          const form2 = JSON.parse(JSON.stringify(this.form2));

          if (form2.business_type === "Others") {
            const other_business_type = trim(this.other_business_type);
            if (!other_business_type) {
              this.$Message.error("Please fill other business type");
              return;
            } else {
              form2.business_type = other_business_type;
            }
          }

          this.loading = true;

          _register({
            role: "exhibitor",
            ...form1,
            ...form2,
            timezone: this.timezone,
            accept_policy: "yes"
          })
            .then(response => {
              const { data } = response;
              this.tempToken = data.token;
              this.step++;
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.link {
  color: @primary-color;
  text-decoration: underline;
}

.container {
  display: flex;
  justify-content: center;
  .box-550 {
    height: 550px;
  }
  .box-820 {
    height: 850px;
  }
  .box-1480 {
    height: 1480px;
  }
  .box-step-2 {
    height: 1360px;
  }
  .box-1580 {
    height: 1500px;
  }
  .box {
    width: 1200px;
    margin-top: 20px;
    box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
    .box-title {
      height: 60px;
      background: linear-gradient(90deg, #a25d72, #2d3b81);
      opacity: 0.9;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-size: 24px;

      font-weight: 700;
      color: #ffffff;
      line-height: 18px;
      padding: 22px 0 0 50px;
    }
    .step {
      width: 680px;
      margin: 33px auto 0 auto;
    }
    .border {
      width: 1100px;
      height: 1px;
      background: #999999;
      margin: 24px auto 0 auto;
    }
  }

  .form-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 40px;
    .title {
      font-size: 28px;

      font-weight: 500;
      color: #292929;
      line-height: 18px;
      margin-bottom: 40px;
    }
    .content {
      margin-bottom: 20px;
      padding-right: 80px;
    }
    .btns {
      display: flex;
      /*align-self: flex-end;*/
      .back {
        margin-right: 10px;
      }
      .tipEng {
        position: absolute;
        left: 0;
        color: @primary-color;
        font-size: 12px;
        line-height: 34px;
        background-color: #fffaf4;
        padding: 0 8px;
        border-radius: 4px;
      }
    }
  }
}

.upload {
  width: 100%;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px;
  cursor: pointer;
  justify-content: center;
  .upload-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 370px;
    .icon {
      color: @primary-color;
    }
  }
}
.cropper {
  width: 100%;
  height: 300px;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}
.top-title {
  position: relative;
  bottom: 13px;
  font-size: 18px;
}
.product-content {
  display: flex;
  width: 1100px;
  flex-wrap: wrap;
  margin-left: 65px;
  .item {
    width: 200px;
    height: 230px;
    border-radius: 5px;
    margin-top: 20px;
    img {
      width: 180px;
      height: 170px;
      margin: 10px;
    }
    .word {
      font-size: 18px;

      font-weight: 500;
      color: #333333;
      line-height: 18px;
      text-align: center;
      position: relative;
      bottom: 5px;
      word-break: break-all;
    }
  }
  .active {
    background: linear-gradient(90deg, #f5a210, #ef8126);
    .word {
      color: #ffffff;
    }
  }
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
  box-shadow: none;
}
.register-form >>> .ivu-input {
  /*width:400px;*/
  height: 36px;
  font-size: 16px;
}
.ivu-select-item {
  font-size: 16px !important;
}
.register-form >>> .ivu-form-item-label {
  font-size: 16px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
}
.ivu-steps >>> .ivu-steps-title {
  font-size: 20px;

  font-weight: 500;
  color: #323538;
  line-height: 18px;
}
.ivu-steps >>> .ivu-steps-head-inner {
  position: relative;
  top: -3px;
}
.ivu-steps >>> .ivu-steps-item {
  padding-top: 3px;
}
</style>
